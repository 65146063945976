<template>
  <div class="card">

    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h4 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp;&nbsp;Component Group List</h4>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="mytable"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="name" data-sortable="true">Name</th>
          <th data-field="p_name" data-sortable="true">Print Name</th>
          <th data-field="hsn" data-sortable="true">HSN</th>
          <th data-field="gstrate" data-sortable="true" data-align="right">Tax Rate %</th>
          <th data-field="itcrate" data-sortable="true" data-align="right">ITC Rate %</th>

        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Modal Window -->
    <div id="componentgroup_modal_window" class="modal fade" role="dialog" ref="componentgroup_modal_window"  >
      <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" style="padding: 0px" >

          <div class="card">

            <div class="card-header header-elements-inline" >
              <h5 class="card-title">Component Group Creation</h5>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="collapse"></a>-->
                  <!--<a class="list-icons-item" data-action="reload"></a>-->
                  <a class="list-icons-item" data-action="remove" @click="hideModal"></a>
                </div>
              </div>
            </div>

            <div class="card-body">
              <form action="#">

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Name:</label>
                  <div class="col-lg-10">
                    <input type="text" class="form-control" placeholder="Enter Group Name here..." maxlength="100">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Print Name:</label>
                  <div class="col-lg-10">
                    <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Nature:</label>
                  <div class="col-lg-10">
                    <select class="form-control form-control-select2" data-fouc>
                      <option value="1">Physical Product</option>
                      <option value="2">Virtual Product</option>
                      <option value="3">Services</option>
                    </select>
                  </div>
                </div>

              </form>
            </div>

            <div class="card-footer">
              <div class="text-right" >
                <button type="button" class="btn btn-primary" >Save<i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>

        </div>
        <!-- End of Modal content-->

      </div>
    </div>
    <!-- End of Modal Window -->

    <FabButton data-toggle="modal" data-target="#componentgroup_modal_window" />

  </div>
</template>

<script>

  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import ComponentGroupForm from '@/components/v1/componentgroup/ComponentGroupForm.vue'
  export default {
    name: 'ComponentGroupView',
    components: {
      ComponentGroupForm,
      FabButton,
      moment
    },
    data () {
      return {
        readonly: false,
        itemgroup: JSON.parse('{"id":"","name":"","p_name":"","hsn":"","type":0,"nature":0,"gstrate":0,"itcrate":100, "unit":{"code":1,"name":"","digits":0,"sys_obj":false}, "conv_fact":1, "subunit":{"code":1,"name":"","digits":0,"sys_obj":false},  "sys_obj":false}'),
        mytable: {},
        units: []
      }
    },
    created () {

    },
    mounted () {
      let self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('.form-control-select2').select2()

      $('[data-toggle="popover"]').popover();

      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }

        }
      });

      $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#componentgroup_modal_window').on('shown.bs.modal', function () {
        $('#txtname').trigger('focus');
      });

      $('#componentgroup_modal_window').on('hide.bs.modal', function () {
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#componentgroup_modal_window').modal('hide');
      });

      self.loadData();

    },
    methods: {
      closeModal () {
        this.$modal.hide('componentgroup_modal_window')
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.group= JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":1,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false}');
        this.$modal.show('componentgroup_modal_window')
      },
      hideModal () {
        this.group= JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":1,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false}');
        this.$modal.show('componentgroup_modal_window')
      },
      clear(){

      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.group = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/componentgroup/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.group = data.data;
            self.$modal.show('componentgroup_modal_window');
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.group.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.group)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/componentgroup/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('componentgroup_modal_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
              // swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/componentgroup/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.mytable.bootstrapTable('load', data.data);
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      save () {
        alert('Welcome')
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>

