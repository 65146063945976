var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"context-table",attrs:{"id":"context-menu"}},[_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.loadData}},[_c('i',{staticClass:"icon-reset",staticStyle:{"color":"blue"}}),_vm._v("Refresh")]),_vm._m(2),_c('div',{staticClass:"dropdown-divider"}),_vm._m(3)])]),_c('div',{ref:"componentgroup_modal_window",staticClass:"modal fade",attrs:{"id":"componentgroup_modal_window","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header header-elements-inline"},[_c('h5',{staticClass:"card-title"},[_vm._v("Component Group Creation")]),_c('div',{staticClass:"header-elements"},[_c('div',{staticClass:"list-icons"},[_c('a',{staticClass:"list-icons-item",attrs:{"data-action":"remove"},on:{"click":_vm.hideModal}})])])]),_vm._m(4),_vm._m(5)])])])]),_c('FabButton',{attrs:{"data-toggle":"modal","data-target":"#componentgroup_modal_window"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-table-toolbar",attrs:{"id":"toolbar"}},[_c('div',{staticClass:"float-left"},[_c('h4',{staticStyle:{"padding-left":"10px","padding-top":"10px","padding-bottom":"0px"}},[_c('span',{staticClass:"icon-list"}),_vm._v("  Component Group List")])]),_c('div',{staticClass:"float-right search"},[_c('input',{staticClass:"form-control",attrs:{"id":"txtsearch","type":"text","placeholder":"Search","autocomplete":"off"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless table-condensed",attrs:{"id":"mytable","data-search":"false","data-pagination":"true","data-show-refresh":"false","data-show-columns":"false","data-page-list":"[10, 25, 50, 100, ALL]","data-show-footer":"false","data-toggle":"context","data-target":".context-table"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"data-field":"id","data-class":"d-none"}},[_vm._v("id")]),_c('th',{attrs:{"data-formatter":"runningFormatter","data-width":"75"}},[_vm._v("S.No")]),_c('th',{attrs:{"data-field":"name","data-sortable":"true"}},[_vm._v("Name")]),_c('th',{attrs:{"data-field":"p_name","data-sortable":"true"}},[_vm._v("Print Name")]),_c('th',{attrs:{"data-field":"hsn","data-sortable":"true"}},[_vm._v("HSN")]),_c('th',{attrs:{"data-field":"gstrate","data-sortable":"true","data-align":"right"}},[_vm._v("Tax Rate %")]),_c('th',{attrs:{"data-field":"itcrate","data-sortable":"true","data-align":"right"}},[_vm._v("ITC Rate %")])])]),_c('tbody'),_c('tfoot')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-pencil7",staticStyle:{"color":"#2d7330"}}),_vm._v("Modify")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-blocked",staticStyle:{"color":"orangered"}}),_vm._v("Remove")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-2 col-form-label"},[_vm._v("Name:")]),_c('div',{staticClass:"col-lg-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Group Name here...","maxlength":"100"}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-2 col-form-label"},[_vm._v("Print Name:")]),_c('div',{staticClass:"col-lg-10"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter your print name here...","maxlength":"100"}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-2 col-form-label"},[_vm._v("Nature:")]),_c('div',{staticClass:"col-lg-10"},[_c('select',{staticClass:"form-control form-control-select2",attrs:{"data-fouc":""}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Physical Product")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Virtual Product")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Services")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Save"),_c('i',{staticClass:"icon-paperplane ml-2"})])])])
}]

export { render, staticRenderFns }