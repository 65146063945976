<template>

  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Component Group Creation</h5>
      <div class="header-elements">
        <div class="list-icons">

          <!--<a class="list-icons-item" data-action="reload"></a>-->
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter Group Name here..." maxlength="100">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Print Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Nature:</label>
          <div class="col-lg-10">
            <select class="form-control form-control-select2" data-fouc>
              <option value="1">Physical Product</option>
              <option value="2">Virtual Product</option>
              <option value="3">Services</option>
            </select>
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentGroupForm',
  data () {
    return {
      itemgroup: {}
    }
  },
  component: {

  },
  created () {
    this.$data.itemgroup = JSON.parse('{"id": 0, "code": "", "name": "", "gstSlab":"","printName": "", "parent": 0, "type": 0, "nature": 0, "hasInsurance": false, "hasAMC": false, "hasComponents": false, "hasBatch": false,"gstSlab": {"id": 0, "code": "", "name": ""},  "depreciationLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "purchaseLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "saleLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "amcPercent": 0.0, "lifeInResidence": 0, "lifeInBranch": 0, "noOfBarcodes": 0, "list": []}')
  },
  mounted () {
    $('.form-control-select2').select2()
  },
  methods: {
    closeWindow() {
      this.$parent.$parent.closeModal();
    },
  }
}
</script>

<style scoped>

</style>
